import React, { useContext, createContext, useState, useEffect } from 'react';
import { useShopifyCookies } from '@shopify/hydrogen-react'
import { useStoresQuery } from "~/hooks/useStoresQuery"

import Geoloc from '~/utils/geoloc'
import QlReq from '~/utils/ql-req'
import UseWardrobe from '~/utils/use-wardrobe'
import * as Queries from '~/helpers/ql-queries'
import { checkMobile } from '~/helpers/helpers'

const ThemeContext = createContext();

const checkCustomer = () => {
  if ((typeof localStorage === "undefined") || !localStorage.getItem('cu')) {
    return false
  }
  
  const cust_obj = JSON.parse(localStorage.getItem('cu'))
  const expires = Date.parse(cust_obj.expires)
  cust_obj['check_expire'] = true
  if ( Date.now() > expires ) return false
  
  return cust_obj
}

const checkCart = () => {
  if ( (typeof localStorage === "undefined") || !localStorage.getItem('cart')) {
    return null
  }
  const loc_cart = JSON.parse(localStorage.getItem('cart'))
  loc_cart['refresh'] = 'loc'
  return loc_cart
}

const checkRecs = () => {
  if ( (typeof localStorage === "undefined") || !localStorage.getItem('cartRecs')) {
    return []
  }
  const loc_recs = JSON.parse(localStorage.getItem('cartRecs'))
  const time_diff = Date.now() - loc_recs.time
  if (time_diff > 10800000) {
    localStorage.removeItem('cartRecs')
    return []
  }
  return loc_recs.recs
}

const checkGender = () => {
  if ((typeof localStorage === "undefined") || (typeof window === "undefined")) return 'men'
  
  const params = new URLSearchParams(window.location.search)
  if (params.get('g')) return params.get('g')

  const is_w = window.location.pathname.includes('/collections/women')
  const set_gend = localStorage.getItem('homeg') ? localStorage.getItem('homeg') : is_w ? 'women' : 'men'

  return set_gend
}

const checkSavedLoc = () => {
  if (typeof localStorage === "undefined") return null
  
  const savd = localStorage.getItem('locate')
  const locarr = savd ? savd.split('|') : []
  let savd_time = locarr[0]
  let savd_loc = locarr[1] ? JSON.parse(locarr[1]) : false

  if (savd_loc && savd_time > (Date.now() - 600000)) return savd_loc
  return null
}


const ThemeProvider = ({ children }) => {
  const { getGeo, mapObj, doStoresSort, doKora } = Geoloc()
  const { checkUserFavs, getOrderHist, checkShopUser } = UseWardrobe()
  const stores_data = useStoresQuery()

  useShopifyCookies({hasUserConsent: true})

  const browser_gender = checkGender()
  const mobile_info = checkMobile()

  const shiplog = (typeof localStorage !== "undefined") && localStorage.getItem('shiplog')

  const [isSHM, setIsSHM] = useState(null)
  const [isMobile, setIsMobile] = useState(mobile_info.mobile)
  const [isTablet, setIsTablet] = useState(mobile_info.tablet)
  const [isTouches, setIsTouches] = useState(mobile_info.touches)
  const [locate, setLocate] = useState(checkSavedLoc())
  const [freeze, setFreeze] = useState(false)
  const [gender, setGender] = useState( browser_gender )
  const [bread, setBread] = useState(false)
  const [pdpVariant, setPdpVariant] = useState(false)
  const [cart, setCart] = useState(checkCart())
  const [cartClose, setCartClose] = useState(false)
  const [cartOpen, setCartOpen] = useState(false)
  const [cartChanging, setCartChanging] = useState(false)
  const [cartError, setCartError] = useState(false)
  const [cartRecs, setCartRecs] = useState(checkRecs())
  const [mobileMenu, setMobileMenu] = useState(false)
  const [collPanel, setCollPanel] = useState(false)
  const [collGender, setCollGender] = useState(null)
  const [storesOpen, setStoresOpen] = useState(false)
  const [blockHeader, setBlockHeader] = useState(false)
  const [pdpInv, setPdpInv] = useState(false)
  const [search, setSearch] = useState(false)
  const [showSizing, setShowSizing] = useState(false)
  const [showGiftCard, setShowGiftCard] = useState(false)
  const [showSticky, setShowSticky] = useState(false)
  const [showStickyCta, setShowStickyCta] = useState(false)
  const [giftInfo, setGiftInfo] = useState(false)
  const [quick, setQuick] = useState(false)
  const [analytics, setAnalytics] = useState({})
  const [customer, setCustomer] = useState(checkCustomer())
  const [custfavs, setCustfavs] = useState(false)
  const [orders, setOrders] = useState([])
  const [order, setOrder] = useState(null)
  const [pageProps, setPageProps] = useState({pageType: null})
  const [transBg, setTransBg] = useState('#fff')
  const [shipPref, setShipPref] = useState('ship')
  const [storesData, setStoresData] = useState(stores_data)
  const [showLoginWarn, setShowLoginWarn] = useState(false)

  const checkSHM = async () => {
    const query = Queries.q_metafield()
    const vars = {
      namespace: 'pima',
      key: 'shopify_holiday_mode'
    }
    const req = await QlReq.post(query, vars)
    let metacheck = req.shop.metafield.value === 'true'
    if (shiplog && metacheck) console.log('SHM on 🎄')
    setIsSHM(metacheck)
  }

  const initLoc = async () => {
    const savd = localStorage.getItem('locate')
    const locarr = savd ? savd.split('|') : []
    let savd_loc = locarr[1] ? JSON.parse(locarr[1]) : {}
    const geo = await getGeo(true, savd_loc)
    setLocate(geo)
  }

  const setMapCoords = async (storeZip=false) => {
    let zip = !!locate.usrZip ? locate.usrZip : locate.zip
    if (storeZip && !locate.usrZip) zip = storeZip
    let map_addy = await mapObj(zip.split('-')[0])
    return map_addy?.geometry?.location
  }

  const getDistances = async (regions, storeZip=false) => {
    const new_regions = [...regions]
    const coords = await setMapCoords(storeZip)
    new_regions.forEach( region => {
      let shortest = false
      region.stores.forEach( store => {
        if (!(coords && store.coordinates)) {
          store.distance = 0
          return
        }
        const dest = new window.google.maps.LatLng(store.coordinates[0], store.coordinates[1])
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(coords, dest)
        let raw_dist = (distance * 0.000621371).toFixed(2)
        raw_dist = raw_dist > 100 ? Math.round(raw_dist) : parseFloat(raw_dist)
        store.distance = raw_dist
        if (raw_dist < shortest || !shortest) shortest = raw_dist
      })
      region.distance = shortest
      region.stores = doStoresSort(region.stores)
    })

    return doStoresSort(new_regions)
  }

  const storesSort = async () => {
    let storeZip = false
    if (!locate.usrZip && !!locate.pdpStore) {
      const pdpstore = storesData.flatMap(x => x.stores).find(x => x.short_name === locate.pdpStore)
      if (pdpstore) storeZip = pdpstore.address_zip
    }
    let sorted = await getDistances(storesData,storeZip)
    sorted = sorted.filter(x => x.stores.length > 0)
    setStoresData(sorted)

    if (locate.currStores.length > 0) {
      const currStore = storesData.flatMap(x => x.stores)
                    .filter(x => !!x.pickup_enabled)
                    .find(x => x.short_name === locate.currStores[0])
      if (currStore) setShipPref('pickup')
    }
  }

  const checkCartValid = async () => {
    const loc_cart = JSON.parse(localStorage.getItem('cart'))

    const vars = {
      id: loc_cart.id
    }
    const query = Queries.q_cart_valid()
    const req = await QlReq.post(query, vars)

    const is_valid = req.cart !== null

    if (!is_valid) {
      localStorage.removeItem('cart')
      setCart(false)
      return
    }

    loc_cart['refresh'] = true
    localStorage.setItem('cart', JSON.stringify(loc_cart))
    setCart(loc_cart)
  }

  const set_refer = () => {
    if (typeof document === 'undefined') return
    const is_local = window.location.hostname.includes('localhost')
    let expires = 1
    let date = new Date()
    date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000))
    expires = `; expires=${date.toUTCString()}`
    const params = new URLSearchParams(window.location.search)
    const obj = {
      p: window.location.pathname,
      r: document.referrer.includes('buckmason.com') ? '' : document.referrer,
      a: params.get('utm_campaign') || false,
      o: params.get('utm_content') || false,
      m: params.get('utm_medium') || false,
      s: params.get('utm_source') || false,
      t: params.get('utm_term') || false,
      d: Date.now
    }
    document.cookie = `re=${JSON.stringify(obj)}${expires};path=/;${is_local ? '' : 'domain=buckmason.com'}`
  }

  const shopUserParam = async () => {
    const params = new URLSearchParams(window.location.search)

    let obj = false
    if (params.get('shid')) {
      obj = {shid: params.get('shid')}
      params.delete('shid')
      const new_url = params.size ? `?${params.toString()}` : window.location.pathname
      window.history.replaceState(null, null, new_url)
    }
    if (params.get('kv_id')) {
      obj = {kv_id: params.get('kv_id')}
      params.delete('kv_id')
      const new_url = params.size ? `?${params.toString()}` : window.location.pathname
      window.history.replaceState(null, null, new_url)
    }
    if (customer) return
    if (!obj) return
    
    const known = await checkShopUser(obj)
    if (!known) return
      
    known.full = false
    setCustomer(known)
    localStorage.setItem('cu',JSON.stringify(known))
  }

  const check_test_params = () => {
    const params = new URLSearchParams(window.location.search)

    if (params.get('outf')) {
      const ttype = params.get('outf')
      if (ttype === 'false') {
        sessionStorage.removeItem('outftest')
      } else {
        sessionStorage.setItem('outftest','true')
      }
    }
    if (params.get('clear')) {
      const ttype = params.get('clear')
      if (ttype === 'cart') {
        localStorage.removeItem('cart')
        setCart(null)
      }
    }
    if (params.get('ward')) {
      const ttype = params.get('ward')
      if (ttype === 'false') {
        localStorage.removeItem('ward')
      } else {
        localStorage.setItem('ward','true')
      }
    }
  }

  useEffect(() => {
    set_refer()
    // doKora()
    check_test_params()
    shopUserParam()
    
    const is_stag = process.env.GATSBY_CURR_ENV === 'development'

    if ( localStorage.getItem('cart') && cart.refresh === 'loc' ) {
      // setCart(JSON.parse(localStorage.getItem('cart')))
      checkCartValid()
    }

    const ward_id = localStorage.getItem('wrdrb')
    if (ward_id) checkFavs(ward_id)

    if (isSHM === null) checkSHM()
    if (!locate) initLoc()

    if (window.location.search.includes('utest=true')) {
      localStorage.setItem('unitary','true')
    }
    if (window.location.search.includes('utest=false')) {
      localStorage.removeItem('unitary')
    }

    document.querySelector('html').setAttribute('hg', browser_gender)
    document.querySelector('html').setAttribute('e', is_stag ? 'd' : 'p')
    

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkFavs = async (fav_id=false) => {
    if (!localStorage.getItem('ward')) return
    if (!customer) return
    if (!!custfavs) return

    let favs = false
    if (fav_id) {
      favs = await checkUserFavs({id: fav_id})
      
    } else if (customer) {
      favs = await checkUserFavs({sid: customer.id})
    }

    if (!favs) {
      setCustfavs(null)
      return
    }

    setCustfavs(favs)
    if (!localStorage.getItem('wrdrb')) localStorage.setItem('wrdrb', favs.id)
  }

  useEffect(() => {
    if (!locate) return
    localStorage.setItem('locate', `${Date.now()}|${JSON.stringify(locate)}`)
    storesSort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locate])

  useEffect(() => {
    document.querySelector('html').style.overflow = freeze ? 'hidden' : ''
  }, [freeze])

  const resize_check = () => {
    const mobile_info = checkMobile()
    setIsMobile(mobile_info.mobile);
    setIsTablet(mobile_info.tablet);
    setIsTouches(mobile_info.touches);
    const html_el = document.querySelector('html')
    html_el.setAttribute('is-m', mobile_info.mobile ? 'true' : 'false')
    mobile_info.touches ? html_el.setAttribute('is-t','true') : html_el.removeAttribute('is-t')
  }

  const key_check = (e) => {
    if (e.key === 'Tab') document.querySelector('html').classList.add('using-keyboard')
  }

  const key_clear = (e) => {
    document.querySelector('html').classList.remove('using-keyboard')
  }

  useEffect(() => {
    window.addEventListener('resize', resize_check, { passive: true });
    window.addEventListener('keydown', key_check);

    if (document.querySelector('html').classList.contains('using-keyboard') ) {
      window.addEventListener('click', key_clear)
    }

    return () => {
      window.removeEventListener('resize', resize_check);
      window.removeEventListener('keydown', key_check);
      // window.removeEventListener('click', key_clear);
    };
  });

  useEffect(() => {
    let loggedout = !customer

    if (loggedout) {
      setCustfavs(null)
      return
    }

    const skip = localStorage.getItem('wrdrb') && !loggedout && !custfavs
    if (!loggedout && !skip) checkFavs()
    loggedout = !customer || !customer?.full
      
    if (!loggedout && !localStorage.getItem('ords') && localStorage.getItem('ward')) getOrderHist(customer)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])

  useEffect(() => {
    document.querySelector('html').setAttribute('hg', gender)
    localStorage.setItem('homeg', gender)
  }, [gender])

  useEffect(() => {
    if (!cartOpen) setCartClose(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartOpen]);



  return (
    <ThemeContext.Provider
      value={{
        isSHM, shiplog, 
        analytics, setAnalytics,
        isMobile, isTablet, isTouches,
        locate, setLocate, 
        gender, setGender,
        bread, setBread,
        freeze, setFreeze,
        pdpVariant, setPdpVariant,
        cart, setCart,
        cartClose, setCartClose,
        cartOpen, setCartOpen,
        cartChanging, setCartChanging,
        cartError, setCartError,
        cartRecs, setCartRecs,
        mobileMenu, setMobileMenu,
        collPanel, setCollPanel,
        collGender, setCollGender,
        storesOpen, setStoresOpen,
        blockHeader, setBlockHeader,
        pdpInv, setPdpInv,
        quick, setQuick,
        search, setSearch,
        showSizing, setShowSizing,
        showGiftCard, setShowGiftCard,
        giftInfo, setGiftInfo,
        customer, setCustomer,
        custfavs, setCustfavs,
        orders, setOrders,
        order, setOrder,
        pageProps, setPageProps,
        transBg, setTransBg,
        shipPref, setShipPref,
        storesData, setStoresData,
        showSticky, setShowSticky,
        showStickyCta, setShowStickyCta,
        showLoginWarn, setShowLoginWarn
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export function useTheme() {
  return useContext(ThemeContext)
}

export { ThemeContext, ThemeProvider }


// const defaultState = {
//   dark: false,
//   toggleDark: () => {},
// }

// const supportsDarkMode = () =>
//   window.matchMedia("(prefers-color-scheme: dark)").matches === true

// const state = {
//   dark: false,
//   panel: 'panel-men',
//   sub: false
// }

// const toggleDark = () => {
//   let dark = !this.state.dark
//   localStorage.setItem("dark", JSON.stringify(dark))
//   this.setState({ dark })
// }
// state,
// toggleDark: toggleDark,
// togglePanel: togglePanel,
// toggleSub: toggleSub,

// useEffect(() => {
//   const lsDark = JSON.parse(localStorage.getItem("dark"))
//   if (lsDark) {
//     this.setState({ dark: lsDark })
//   } else if (supportsDarkMode()) {
//     this.setState({ dark: true })
//   }
// }, []);
