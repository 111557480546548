import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { useTheme } from "~/contexts/ThemeContext"
import { useHeaderQuery } from "~/hooks/useHeaderQuery"
import useAnalyticsHook from '~/utils/analytics'

import Logo from '~/components/icons/logo'
import { IconChevRight } from '~/components/icons/chevrons'
import IconClose from '~/components/icons/close'

import { aria_btn, cta_link, bg } from "~/styles/app.module.scss"
import * as styles from "~/styles/panels/mobile_menu.module.scss"

const MobileMenu = ({ setShowMobileMenu }) => {
  const { gender, setGender, setFreeze, customer, isTouches, isMobile } = useTheme()
  const { headers, journals } = useHeaderQuery()
  const { ga_nav_click } = useAnalyticsHook()

  const [isLogin, setIsLogin] = useState(false)

  const bgRef = useRef(null)
  const menuRef = useRef(null)
  const backRef = useRef(null)
  const contentRef = useRef(null)
  const bottomRef = useRef(null)

  const topMenu = headers.find( x => x.name === 'top' )
  const subMenus = headers.filter( x => x.name === 'new men' || x.name === 'new women' )
  const journal_sub = journals.find(x => x.handle === 'head-journal')
  if (journal_sub) {
    journal_sub['name'] = 'journal'
    subMenus.push(journal_sub)
  }

  let curr_menu = (gender === 'both') 
    ? false 
    : sessionStorage.getItem('mobjorn') 
    ? subMenus.find(x => x.handle === 'head-journal') 
    : subMenus.find( x => x.name === `new ${gender}` ).menu

  // const [journ, setJourn] = useState(null)
  const [menu, setMenu] = useState(curr_menu)

  const specials = ['Highlights','Promo','Journal','Collaborations','Featured']
  const is_stag = process.env.GATSBY_CURR_ENV === 'development'
  const auth_url = is_stag ? 'https://buckmason-rms.pima.io/auth' : 'https://orders.buckmason.com/auth'

  

  const handleClick = ( gender ) => {
    if (gender !== 'the journal') {
      localStorage.setItem('homeg', gender)
      sessionStorage.removeItem('mobjorn')
      setGender(gender)
    } else {
      sessionStorage.setItem('mobjorn', 'true')
      curr_menu = subMenus.find(x => x.handle === 'head-journal')
    }
    setMenu(curr_menu)
  }

  const top_journal = topMenu.menu.find(x => x.name === 'The Journal')

  const has_collabsfeats = () => {
    const collabsfeats = menu.filter(x => x.name === 'Collaborations' || x.name === 'Featured') 
    if (collabsfeats.length < 1) return false

    return collabsfeats
  }

  const has_promo = () => {
    return menu.find(x => x.name === 'Promo')
  }

  const journ_stories = (data) => {
    return data.filter(x => x.name === 'stories')
  }

  const journ_other = (data) => {
    return data.filter(x => x.name !== 'stories')
  }

  const handleClose = () => {
    const bg_el = bgRef.current
    if (!isMobile && (isTouches || window.innerWidth <= 1120) && bgRef.current) {
      bg_el.setAttribute('aria-hidden', 'true')
    }

    const menu_el = menuRef.current
    document.querySelector('main').style.opacity = 1
    setFreeze(false)
    
    menu_el.classList.add(styles.off)
    menu_el.setAttribute('aria-hidden', 'true')

    // if (isTouches) {
    //   document.querySelector('main').style.opacity = 1
    // }
    setTimeout(() => {
      // menu_el.classList.remove(styles.off)
      setShowMobileMenu(false)
    }, 350)

    // setShowMobileMenu(false)
  }

  const gaClick = (name, url) => {
    const obj = {
      hierarchy: `top > ${name}`,
      text: name,
      url: url
    }
    ga_nav_click(obj)
  }



  const openAnimate = (direct) => {
    let delay = 30

    if (menu) {
      setTimeout(() => {
        const back_el = backRef.current
        if (back_el) back_el.style.opacity = 1
        document.querySelectorAll(`.${styles.content} a`).forEach(x => {
          setTimeout(() => {
            x.style.opacity = direct
          }, delay)
          delay += 30
        })
      }, 150)
      return
    }
    const bottom_el = bottomRef.current
    if (!bottom_el) return
    bottom_el.style.opacity = direct
    bottom_el.style.transform = 'translate(0,0)'
    setTimeout(() => {
      document.querySelectorAll(`.${styles.bottom} .${styles.top_link}`).forEach(x => {
        setTimeout(() => {
          x.style.opacity = direct
        }, delay)
        delay += 30
      })
      document.querySelectorAll(`.${styles.bottom} .${styles.acctlink}`).forEach(x => {
        setTimeout(() => {
          x.style.opacity = direct
        }, delay)
        delay += 30
      })
    }, 150)
  }

  const subMenuIn = () => {
    const content_el = contentRef.current
    const back_el = backRef.current
    if (!back_el) return
    if (content_el) {
      content_el.style.transform = 'translate(0,0)'
      back_el.style.opacity = 1
      back_el.style.transform = 'translate(0,0)'
      setTimeout(() => {
        content_el.classList.add(`${styles.contentscroll}`)
      }, 350)
    }
  }

  const subMenuOut = () => {
    const content_el = contentRef.current
    const back_el = backRef.current
    if (content_el) {
      content_el.style.transform = ''
      back_el.style.opacity = 0
      back_el.style.transform = ''
      setTimeout(() => {
        content_el.classList.remove(`${styles.contentscroll}`)
      }, 350)
    }
  }

  const handleBack = () => {
    subMenuOut()
    setTimeout(() => {
      openAnimate(0)
    }, 20)
    setTimeout(() => {
      setMenu(false)
    }, 150)
    setTimeout(() => {
      openAnimate(1)
      setMenu(false)
    }, 210)
  }


  
  useEffect(() => {
    if (!isMobile && (isTouches || window.innerWidth <= 1120) && bgRef.current) {
      setTimeout(() => {
        if (bgRef.current) bgRef.current.setAttribute('aria-hidden', 'false')
      }, 50)
    }
    if (isMobile) {
      document.querySelector('main').style.opacity = 0
    }
    setTimeout(() => {
      subMenuIn()
    }, 150)
    setTimeout(() => {
      openAnimate(1)
    }, 200)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const menu_el = menuRef.current
    setFreeze(true)
    setTimeout(() => {
      if (menu_el) menu_el.setAttribute('aria-hidden', 'false')
    }, 100)
  }, [setFreeze])

  useEffect(() => {
    const is_journ = sessionStorage.getItem('mobjorn') 

    let curr_menu = is_journ 
      ? subMenus.find(x => x.handle === 'head-journal') 
      : menu 
      ? subMenus.find( x => x.name === `new ${gender}` ).menu 
      : false
    
    setMenu(curr_menu)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gender])

  useEffect(() => {
    if (!!menu) {
      setTimeout(() => {
        subMenuIn()
      }, 150)
      setTimeout(() => {
        openAnimate(1)
      }, 200)
      return
    }
    setTimeout(() => {openAnimate(1)}, 50)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  useEffect(() => {
    if (!customer) {
      setIsLogin(false)
      return
    }
    if (customer.full) setIsLogin(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer])


  return (
    <>
      <button 
        className={[aria_btn, bg].join(' ')} 
        aria-hidden="true" 
        ref={bgRef} 
        onClick={(e)=> {handleClose()}}
        aria-label={'Close menu'} 
      />
      <nav 
        ref={menuRef}
        className={styles.menu}
        aria-label="Main menu"
        role="dialog" 
        aria-modal="true"
        aria-hidden="true"
      >
        <button 
          className={[aria_btn, styles.close].join(' ')}
          aria-label="Close menu" 
          onClick={(e)=> {handleClose()}}
        >
          <IconClose icon_classes={''} />
        </button>

        <Link to={"/"} className={styles.logo} aria-label="Go to homepage">
          <Logo 
            logo_className={''} 
            onClick={(e)=> {handleClose()}}
          />
        </Link>

        {menu ? (
          <>
            <button 
              ref={backRef}
              className={[aria_btn, styles.back, cta_link].join(' ')}
              onClick={(e)=>{ handleBack(); sessionStorage.removeItem('mobjorn')}}
            >
              <span>BACK</span>
            </button>
            <div className={styles.content} ref={contentRef}>
              <div 
                className={[styles.sub, styles.panel].join(' ')}
                aria-hidden="false" 
                aria-expanded="true" 
              >
                {menu.name === 'journal' ? (
                  <Link 
                    to={`/blogs/journal`}
                    className={[styles.top_link].join(' ')}
                    onClick={(e)=> handleClose()}
                  >
                    Journal
                  </Link>
                ) : (
                  <Link 
                    to={`/?g=${gender}`}
                    className={[styles.top_link].join(' ')}
                    onClick={(e)=> handleClose()}
                  >
                    {gender}
                  </Link>
                )}
                {(menu && menu.name !== 'journal') && (
                  <>
                    {menu.map( (item, index) => {

                      return (
                        <React.Fragment key={`subcol-${gender}${index}`}>

                          {item.name === 'Highlights' && (
                            <ul 
                              className={[styles.group, styles.features].join(' ')}
                              aria-hidden={'false'}
                            > 
                              {item.group_links.map( (link, index) => 
                                <li key={`mobsublink-${gender}${index}`}>
                                  <Link
                                    className={styles.link}
                                    to={link.url} 
                                    onClick={(e)=> {
                                      handleClose()
                                      gaClick(`Mobi: ${link.name}`, link.url)
                                    }}
                                  >
                                    {link.name}
                                  </Link>
                                </li>
                              )}
                            </ul>
                          )}
                          {(!specials.includes(item.name)) && (
                            <ul 
                              className={styles.group}
                              aria-hidden={'false'}
                            > 
                              {item.group_links.map( (link,index) =>
                                <li key={`${item.name}${index}`}>
                                  <Link
                                    className={styles.link}
                                    to={link.url} 
                                    onClick={(e)=> {
                                      handleClose();
                                      gaClick(`Mobi: ${link.name}`, link.url)
                                    }}
                                  >
                                    {link.name}
                                  </Link>
                                </li>
                              )}
                            </ul>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </>
                )}
                {(menu && menu.name !== 'journal' && has_collabsfeats()) && (
                  <div className={[styles.group, styles.journ_group].join(' ')}>
                    {has_collabsfeats().map(group => 
                      <ul 
                        key={`${gender}${group.name}`}
                        className={styles.group}
                        aria-hidden={'false'}
                      > 
                        <li>
                          <div className={styles.title}>
                            {group.name}
                          </div>
                        </li>
                        {group.group_links.map( (link,index) =>
                          <li key={`${group.name}${index}`}>
                            <Link
                              className={styles.link}
                              to={link.url} 
                              onClick={(e)=> {
                                handleClose();
                                gaClick(`Mobi: ${link.name}`, link.url)
                              }}
                            >
                              {link.name}
                            </Link>
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
                )}
                {(menu && menu.name !== 'journal' && has_promo()) && (
                  <div className={styles.promos}>
                    {has_promo().group_links.map( (link, index) => 
                      <div key={`promo-${gender}${index}`} className={styles.promo_item}>
                        <Link 
                          to={link.url}
                          onClick={(e)=> {
                            handleClose();
                            gaClick(`Mobi: ${link.name}`, link.url)
                          }}
                        >
                          <img 
                            className={styles.promo_img} src={link.image_url}
                            alt="" 
                            loading="lazy" 
                            width="300" 
                            height="400"
                          />
                        </Link>
                        <p>
                          <Link 
                            className={styles.link}
                            to={link.url}
                            onClick={(e)=> {
                              handleClose();
                              gaClick(`Mobi: ${link.name}`, link.url)
                            }}
                          >
                            {link.name}
                          </Link>
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {(menu && menu.name === 'journal') && (
                  <>
                    {journ_stories(menu.data).length > 0 && (
                      <div className={[styles.group, styles.journ_group].join(' ')}>
                        <div className={styles.title}>STORIES</div>
                        {journ_stories(menu.data).map( (group) => 
                          <ul key={`${gender}${group.name}${group.gender}`} className={styles.sub_group}>
                            <li>
                              <div className={[styles.title, styles.subtitle].join(' ')}>
                                {group.gender === 'both' ? 'Buck Mason' : group.gender}
                              </div>
                            </li>
                            {group.menu.map( (link) => 
                              <li key={`sublink-${gender}${link.handle}`}>
                                <Link 
                                  className={styles.link}
                                  to={`/blogs/journal/${link.handle}`} 
                                  onClick={(e)=> handleClose()}
                                >
                                  {link.title}
                                </Link>
                              </li>
                            )}
                          </ul>
                        )}
                      </div>
                    )}
                    {journ_other(menu.data).length > 0 && (
                      <>
                        {journ_other(menu.data).map( (group,index) => 
                          <div key={`other${gender}${group.name}`} className={[styles.group, styles.journ_group].join(' ')}>
                            <div className={styles.title}>{group.name}</div>
                            <ul className={styles.sub_group}>
                              {group.menu.slice(0,5).map( (link) => 
                                <li key={`sublink-${gender}${link.handle}`}>
                                  <Link 
                                    className={styles.link}
                                    to={`/blogs/journal/${link.handle}`} 
                                    onClick={(e)=> handleClose()}
                                  >
                                    {link.title}
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                

                <div className={styles.bottom} style={{marginTop: '3em'}}>
                  <Link 
                    to={'/pages/our-stores'} 
                    className={styles.link} 
                    onClick={(e)=> {
                      handleClose();
                      gaClick('Mobi: Our Stores', '/pages/our-stores')
                    }}
                  >
                    Our Stores
                  </Link>

                  <a 
                    href={isLogin ? '/account' : auth_url}
                    className={[styles.link].join(' ')} 
                    aria-label="Your account"
                    onClick={(e)=> {
                      handleClose();
                      gaClick('Mobi: Account', '/account')
                    }}
                  >
                    Account
                  </a>
                  {/*  if customer 
                    <a className={[styles.nd_headTop_btn, styles.nd_headNomob].join(' ')} to="/account" login-link aria-label="Your account">
                      <IconAccount icon_classes={styles.nicon}></IconAccount>
                    </a>
                   else 
                     assign redirect_url = canonical_url | remove: shop.url | url_encode 
                    <a className={[styles.nd_headTop_btn, styles.nd_headNomob].join(' ')} to="/account/login?back={{ redirect_url }}" login-link aria-label="Login to account">
                      <IconAccount icon_classes={styles.nicon}></IconAccount>
                    </a>
                   endif - */}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.content} />
        )}

        {!menu && (
          <div className={styles.bottom} ref={bottomRef}>
            {topMenu.menu.filter(x => x.name !== 'The Journal').map( (item, index) => {
              const gender = item.name.toLowerCase()

              return(
                item.url === 'headsp_main-btn' ? (
                  <button 
                    key={`menu-${index}`}
                    className={[aria_btn, styles.top_link].join(' ')}
                    onClick={(e) => {
                      handleClick(gender);
                      gaClick(`Mobi: ${item.name}`, `/?${gender}`)
                    }}
                    mob-menu={item.name.toLowerCase()}
                  >
                    {item.name}
                    <IconChevRight />
                  </button>
                ) : (
                  <Link 
                    key={`headtop-${index}`}
                    to={item.url} 
                    className={[aria_btn, styles.top_link].join(' ')}
                    onClick={(e)=> {
                      handleClose();
                      gaClick(`Mobi: ${item.name}`, item.url)
                    }}
                    knit-lnk={item.url === '/pages/buck-mason-knitting-mills' ? '' : undefined}
                  >
                     {item.name}
                  </Link>
                )
              )
            })}
            {top_journal && (
              <button 
                className={[aria_btn, styles.top_link].join(' ')}
                onClick={(e) => {
                  handleClick(top_journal.name.toLowerCase());
                  gaClick(`Mobi: ${top_journal.name}`, `/blogs/journal`)
                }}
              >
                {top_journal.name}
                <IconChevRight />
              </button>
            )}
            <br/>
            <Link 
              to={'/pages/our-stores'} 
              className={[styles.link, styles.acctlink].join(' ')} 
              onClick={(e)=> {
                handleClose();
                gaClick('Mobi: Our Stores', '/pages/our-stores')
              }}
            >
              Our Stores
            </Link>

            <a 
              href={isLogin ? '/account' : auth_url}
              className={[styles.link, styles.acctlink].join(' ')} 
              aria-label="Your account"
              onClick={(e)=> {
                handleClose();
                gaClick('Mobi: Account', '/account')
              }}
            >
              Account
            </a>
          </div>
        )}
      </nav>
    </>
  )
}

export default MobileMenu


